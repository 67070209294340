import React from 'react';
import './Datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

class Timepicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            type: 'Date Picker',
            set: this.props.bindTo.currentSetIndex,
            question: this.props.bindTo.id
        };
    }

    render() {
        const { date } = this.state;
        return (
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text text-secondary" id="basic-addon1"><FontAwesomeIcon icon={faClock} /></span>
                </div>
            <input type="time"
                className="form-control col-lg-6 bg-theme3 text-theme3"
                data-set={this.props.bindTo.currentSetIndex}
                data-question={this.props.bindTo.id}
                name={'q' + this.props.bindTo.id}
                id={'q' + this.props.bindTo.id}
                defaultValue={this.props.bindTo.defaultValue}
                onChange={this.props.onChange} />
            </div>
        );
    }
}

export default Timepicker;