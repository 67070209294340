import React from 'react';
import { Link } from 'react-router-dom';

export function SurveyLink(props) {

    return (
        <Link
            className="list-group-item bg-theme3 text-theme3 list-group-item-action"
            to={{ pathname: props.url }}>
            {props.title}
        </Link>
    );

}