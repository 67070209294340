import React from 'react';
import { Row, Col, ListGroupItem } from 'reactstrap';
import RadioButtonList from '../form-controls/RadioButtonList';
import RadioButtonListWithOther from '../form-controls/RadioButtonListWithOther';
import { DropdownList } from '../form-controls/DropdownList';
import Datepicker from '../form-controls/Datepicker';
import LongText from '../form-controls/LongText';
import SingleToggle from '../form-controls/SingleToggle';
import ShortText from '../form-controls/ShortText';
import CheckBoxList from '../form-controls/CheckBoxList';
import YesNoRadioButtons from '../form-controls/YesNoRadioButtons';
import Timepicker from '../form-controls/Timepicker';
import PatientSearch from '../form-controls/PatientSearch';

class Question extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            text: '',
            overrideText: '',
            help: '',
            type: '',
            responses: [],
            userResponse: '',
            isHidden: false,
            currentQuestionIndex: 0,
            currentSetIndex: 0,
            validationMessages: []
        };
    }

    componentDidMount() {
        this.setState({
            id: this.props.item.id,
            text: this.props.item.text,
            overrideText: this.props.item.overrideText,
            help: this.props.item.help,
            type: this.props.item.type,
            responses: this.props.item.responses,
            userResponse: this.props.item.defaultValue || (this.props.item.userResponse || ''),
            displayColumns: this.props.item.displayColumns,
            isHidden: this.props.item.isHidden,
            isMandatory: this.props.item.isMandatory,
            currentQuestionIndex: this.props.currentQuestionIndex,
            currentSetIndex: this.props.currentSetIndex,
            validationMessages: this.props.item.validationMessages
        });        
    }
    
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.item.validationMessages && nextProps.item.validationMessages !== prevState.validationMessages) {
            return { validationMessages: nextProps.item.validationMessages };
        }
        else return null;
    }

    renderQuestionType() {
        switch (this.state.type) {
            case 'Date Picker':
                return <Datepicker
                    key={this.state.currentQuestionIndex}
                    bindTo={this.state}
                    onChange={this.props.changeEventHandler} />;
            case 'Long Text':
                return <LongText
                    key={this.state.currentQuestionIndex}
                    bindTo={this.state}
                    onChange={this.props.changeEventHandler} />;
            case 'Short Text':
                return <ShortText
                    key={this.state.currentQuestionIndex}
                    bindTo={this.state}
                    onChange={this.props.changeEventHandler} />;
            case 'Dropdown List':
                return <DropdownList
                    key={this.state.currentQuestionIndex}
                    bindTo={this.state}
                    onChange={this.props.changeEventHandler} />;
            case 'Radiobutton List':
                return <RadioButtonList
                    key={this.state.currentQuestionIndex}
                    bindTo={this.state}
                    onChange={this.props.changeEventHandler} />;
            case 'Checkbox List':
                return <CheckBoxList
                    key={this.state.currentQuestionIndex}
                    bindTo={this.state}
                    onChange={this.props.changeEventHandler} />;
            case 'Radiobutton List With Other':
                return <RadioButtonListWithOther
                    key={this.state.currentQuestionIndex}
                    bindTo={this.state}
                    onChange={this.props.changeEventHandler} />;
            case 'Single Toggle':
                return <SingleToggle
                    key={this.state.currentQuestionIndex}
                    bindTo={this.state}
                    onChange={this.props.changeEventHandler} />;
            case 'Yes No Radiobuttons':
                return <YesNoRadioButtons
                    key={this.state.currentQuestionIndex}
                    bindTo={this.state}
                    onChange={this.props.changeEventHandler} />;
            case 'Timepicker':
                return <Timepicker
                    key={this.state.currentQuestionIndex}
                    bindTo={this.state}
                    onChange={this.props.changeEventHandler} />;
            case 'Patient Search':
                return <PatientSearch
                    key={this.state.currentQuestionIndex}
                    bindTo={this.state}
                    onChange={this.props.changeEventHandler} />;
            default:
                return <div></div>;
        }
    }

    renderQuestion(item) {
        return <ListGroupItem className="bg-light text-theme3 py-0" key={'q' + item.id}>
            <Row>
                <Col xs="12" lg="6" className="py-2">
                    <strong>
                        {item.overrideText || item.text} {item.isMandatory ?
                            <span className="font-weight-bold text-danger">*</span> : ''}
                    </strong>
                    <div className="small text-secondary">
                        {item.help}
                    </div>
                    {item.type === 'Radiobutton List' &&
                        <div className="small text-secondary">
                            Select one answer
                        </div>}
                    {item.type === 'Checkbox List' &&
                        <div className="small text-secondary">
                            Select one or more answers that apply
                        </div>}
                    {item.validationMessages ?
                        <span className="validation" id={'validationErrorsQ' + item.id}>
                            <ul className="text-danger font-weight-bold my-1 text-theme4 bg-theme4 small">
                            {item.validationMessages.map(message => {
                                return <li>{message}</li>
                            })}
                            </ul>
                        </span> : ''
                    }
                </Col>
                <Col xs="12" lg="6" className="py-2 bg-response">
                    {this.renderQuestionType()}
                </Col>
            </Row>
        </ListGroupItem>
    }

    renderQuestionWithColumns(item) {
        return <ListGroupItem className="bg-light text-theme3 py-0" key={'q' + item.id}>
            <Row>
                <Col xs="12" className="py-2">
                    <strong>
                        {item.overrideText || item.text} {item.isMandatory ?
                            <span className="font-weight-bold text-danger">*</span> : ''}
                    </strong>
                    <div className="small text-secondary">
                        {item.help}
                    </div>
                    {item.type === 'Radiobutton List' &&
                        <div className="small text-secondary">
                            Select one answer
                        </div>}
                    {item.type === 'Checkbox List' &&
                        <div className="small text-secondary">
                            Select one or more answers that apply
                        </div>}
                    {item.validationMessages ?
                        <span className="validation" id={'validationErrorsQ' + item.id}>
                            <ul className="text-danger font-weight-bold my-1 text-theme4 bg-theme4 small">
                                {item.validationMessages.map(message => {
                                    return <li>{message}</li>
                                })}
                            </ul>
                        </span> : ''
                    }
                </Col>
                <Col xs="12" className="py-2 bg-response">
                    {this.renderQuestionType()}
                </Col>
            </Row>
        </ListGroupItem>
    }

    render() {
        let content = this.state.isHidden ? 
            <input type="hidden" 
                name={'q' + this.state.id}
                value={this.state.userResponse}
                data-set={this.state.currentSetIndex}
                data-question={this.state.id} /> :
                (this.state.displayColumns ? this.renderQuestionWithColumns(this.state) : this.renderQuestion(this.state))
        return (content);
    }
}

export default Question;