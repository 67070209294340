import React, { useEffect, useState } from 'react';
import { CustomInput } from 'reactstrap';
import IQuestion from '../../models/IQuestion';
import './SingleToggle.css';

interface ISingleToggleProps {
    bindTo: IQuestion,
    onChange: ((e: any) => void) | undefined | null
}

const SingleToggle : React.FC<ISingleToggleProps> = ({bindTo, onChange}) => {
    const [isChecked, setIsChecked] = useState<Boolean>(false);
    
    useEffect(() => {
        setIsChecked( !(bindTo.defaultValue || bindTo.userResponse) ? false : true)
    }, []) 

    const handleOnChange = (e: any) => {
        e.target.value = !isChecked;
        setIsChecked(!isChecked);
        if (onChange !== undefined && onChange !== null)
            onChange(e);
    }

    return (
        <CustomInput
            type="switch"
            className="py-2"
            name={'q' + bindTo.id}
            data-set={bindTo.currentSetIndex}
            data-question={bindTo.id}
            onChange={handleOnChange}
            id={'q' + bindTo.id}
        />
    );
}

export default SingleToggle;