import React from 'react';

class ShortText extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            text: '',
            overrideText: '',
            help: '',
            type: '',
            responses: [],
            userResponse: '',
            isHidden: false,
            currentQuestionIndex: 0,
            currentSetIndex: 0,
            validationMessages: []
        };
    }

    componentDidMount() {
        this.setState({
            id: this.props.bindTo.id,
            text: this.props.bindTo.text,
            overrideText: this.props.bindTo.overrideText,
            help: this.props.bindTo.help,
            type: this.props.bindTo.type,
            responses: this.props.bindTo.responses,
            userResponse: this.props.bindTo.defaultValue || (this.props.bindTo.userResponse || ''),
            isHidden: this.props.bindTo.isHidden,
            currentQuestionIndex: this.props.currentQuestionIndex,
            currentSetIndex: this.props.currentSetIndex,
            validationMessages: this.props.bindTo.validationMessages
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.bindTo.validationMessages && nextProps.bindTo.validationMessages !== prevState.validationMessages) {
            return { validationMessages: nextProps.bindTo.validationMessages };
        }
        else return null;
    }

    render() {

        return (
            <input type="text" required
                className="form-control text-theme3 bg-theme3"
                name={'q' + this.props.bindTo.id}
                data-set={this.props.bindTo.currentSetIndex}
                data-question={this.props.bindTo.id}
                onChange={this.props.onChange} />
        );
    }
}

export default ShortText;