import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Footer extends Component {
  static displayName = Footer.name;

  constructor (props) {
    super(props);
  }

  render () {
    return (
      <footer>
            <div className="py-3 text-xs text-center text-theme1">
                &copy; 2020-{new Date().getFullYear()} The Rotherham NHS Foundation Trust | Visit our website for more information: <a className='text-theme1' href="www.therotherhamft.nhs.uk" target="_blank">www.therotherhamft.nhs.uk</a>.
            </div>
      </footer>
    );
  }
}
