import React from 'react';
import './RadioButtonListWithOther.css';

class RadioButtonListWithOther extends React.Component {

    render() {
        return (
            <div className="radio-container">
                <ul className="form-group form-group-radio mb-0">
                    {this.props.bindTo.responses.map(response =>
                    <li className="radio-inline bg-theme3" key={response.value}>
                            <input type="radio" name={'q' + this.props.bindTo.id}
                                data-set={this.props.bindTo.currentSetIndex}
                                data-question={this.props.bindTo.id}
                                className="radio-btn f0rm-check-input"
                                value={response.value}
                                onChange={this.props.onChange} />
                        <label className="f0rm-check-label label text-theme3">
                            {response.text}
                        </label>
                        <div className="bullet">
                            <div className="line zero"></div>
                            <div className="line one"></div>
                            <div className="line two"></div>
                            <div className="line three"></div>
                            <div className="line four"></div>
                            <div className="line five"></div>
                            <div className="line six"></div>
                            <div className="line seven"></div>
                        </div>
                    </li>
                    )}
                    <li className="radio-inline bg-theme3">
                        <input type="radio" name={'q' + this.props.bindTo.id}
                            data-set={this.props.bindTo.currentSetIndex}
                            data-question={this.props.bindTo.id}
                            onChange={this.props.onChange} 
                            defaultValue=""
                            className="radio-btn f0rm-check-input" />
                        <label className="f0rm-check-label text-theme3 label">
                            Other
                        </label>
                        <div className="bullet">
                            <div className="line zero"></div>
                            <div className="line one"></div>
                            <div className="line two"></div>
                            <div className="line three"></div>
                            <div className="line four"></div>
                            <div className="line five"></div>
                            <div className="line six"></div>
                            <div className="line seven"></div>
                        </div>
                    </li>
                </ul>
                <ul className="form-group form-group-radio">
                    <li className="radio-inline bg-theme3" style={{ height: '50px' }}>
                        <input type="text"
                            data-set={this.props.set}
                            data-question={this.props.bindTo.id}
                            onChange={this.props.onChange} name={'q' + this.props.bindTo.id} className="form-control bg-theme3 text-theme3" value="" />
                    </li>
                </ul>
            </div>);
    }
}

export default RadioButtonListWithOther;