import React, { useState, useEffect } from 'react';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SurveyCard } from '../survey-select/SurveyCard';
import { SurveyCardFilter } from '../survey-select/SurveyCardFilter';

export function FftCategoryDisplay(props) {
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [formsVisible, setFormsVisible] = useState(false);

    useEffect(() => {
        if (selectedFilter) {
            setFormsVisible(true);
        } else {
            setFormsVisible(false);
        }
    }, [selectedFilter]);

    const hospitalLinks = [
        { url: "/Forms/FFT/1", title: "Inpatients", type: 'Adult' },
        { url: "/Forms/FFT/10010", title: "Inpatients", type: 'Child' },
        { url: "/Forms/FFT/3", title: "Outpatients", type: 'Adult' },
        { url: "/Forms/FFT/6", title: "Outpatients", type: 'Child' },
        { url: "/Forms/FFT/10005", title: "Day Surgery", type: 'Adult' },
        { url: "/Forms/FFT/4", title: "Day Case", type: 'Adult' },
        { url: "/Forms/FFT/10006", title: "Labour & Delivery", type: 'Adult' }
    ];
    const communityLinks = [
        { url: "/Forms/FFT/10007", title: "Community", type: 'Adult' },
        { url: "/Forms/FFT/10013", title: "Long Covid Service", type: 'Adult' }
    ];
    const ueccLinks = [
        { url: "/Forms/FFT/5", title: "UECC", type: 'Adult' }
    ];
    const otherLinks = [
        { url: "/Forms/FFT/10011", title: "Parent/Carer", type: 'Parent/Carer' }
    ];

    const filterCallback = (e) => {
        setSelectedFilter(e);
    }

    return (
        <div className="container-fluid">
            <Breadcrumb style={{ backgroundColor: '#768692'}}>
                <BreadcrumbItem><Link className="text-secondary" to="/">Home</Link></BreadcrumbItem>
                <BreadcrumbItem><Link className="text-secondary" to={'/category/FFT'}>FFT</Link></BreadcrumbItem>
            </Breadcrumb>
            <SurveyCardFilter handler={filterCallback} selectedFilter={selectedFilter} />
            {formsVisible && (
                <Row>
                    <Col xs="12" md="6" lg="4">
                        <SurveyCard title="Hospital" links={hospitalLinks} filter={selectedFilter} />
                    </Col>
                    <Col xs="12" md="6" lg="4">
                        <SurveyCard title="Community" links={communityLinks} filter={selectedFilter} />
                    </Col>
                    <Col xs="12" md="6" lg="4">
                        <SurveyCard title="Urgent & Emergency Care Centre" links={ueccLinks} filter={selectedFilter} />
                    </Col>
                    <Col xs="12" md="6" lg="4">
                        <SurveyCard title="Other FFT" links={otherLinks} filter={selectedFilter} />
                    </Col>
                </Row>
            )}
        </div>
    );

}