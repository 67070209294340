import React, { useEffect, useState } from 'react';

export function Stepper({count, selectedStep}) {
    const [ steps, setSteps ] = useState([]);

    useEffect(() => {
        let stepArray = [];
        for (let s = 0; s < count; s++) 
            stepArray.push({id: s+1, selected: (s+1) === selectedStep});
        
        setSteps(stepArray);
    },[])

    const calculateProgress = () => {
        if (selectedStep === 1) return 0;
        if (selectedStep === count) return 100;        
        return selectedStep / (count + 1) * 100;
    }

    if (steps && steps.length)
    return (
    <div className="steps">
        <progress id="progress" value={calculateProgress()} max={100}></progress>
        {steps.map((step, stepKey) => (
            <div className="step-item" key={`step${stepKey}`}>
                <span className={`step-button text-center ${step.selected || selectedStep >= step.id? "bg-success font-weight-bold" : ""}`} aria-selected={step.selected}>
                    {step.id}
                </span>
            </div>
        ))}
    </div>
    ) 
    else {
        return (<></>)
    }
}
