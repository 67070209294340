import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import QuestionSet from '../other-controls/QuestionSet';
import { Stepper } from '../other-controls/Stepper';
import { WaveTopBottomLoading } from 'react-loadingg';
import { useGlobalState } from '../../utils/globalState';
import './Forms.css';

export const Forms = (props) => {
    const formTopRef = React.createRef();
    const submitButtonRef = React.createRef();
    const [state, dispatch] = useGlobalState();
    const currentFormId = props.match.params.id;
    const currentFormCategory = props.match.params.category;
    const [currentForm, setCurrentForm] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => { populateForms(); }, []);
    useEffect(() => {
        if (currentForm) {
            switch (currentForm.theme) {
                case 'Red Theme':
                    document.body.classList.remove('green-theme');
                    document.body.classList.remove('child-theme');
                    document.body.classList.add('red-theme');
                    break;
                case 'Child Theme':
                    document.body.classList.remove('green-theme');
                    document.body.classList.remove('red-theme');
                    document.body.classList.add('child-theme');
                    break;
                case 'Green Theme':
                    document.body.classList.remove('red-theme');
                    document.body.classList.remove('child-theme');
                    document.body.classList.add('green-theme');
                    break;
                default:
                    document.body.classList.remove('green-theme');
                    document.body.classList.remove('child-theme');
                    document.body.classList.remove('red-theme');
            }
            setCurrentForm(frm => {
                frm.dataEntryUser = state.loggedInUser;
                frm.dataEntryMethod = state.entryMethod;
                return frm;
            });
            dispatch({ selectedCategory: currentFormCategory });
        }
    }, [currentForm, setCurrentForm]);

    const populateForms = async() => {
        await fetch(`api/Forms/${currentFormCategory}/${currentFormId}`)
            .then((response) => {
                switch (response.status) {
                    case 200:
                        setIsSuccess(true);
                        setIsLoading(false);
                        return response.json();
                    default:
                        setIsLoading(false);
                        return;
                }
            })
            .then((response) => {
                if (response)
                    setCurrentForm(response);
            });
    }

    const handleOnChange = (e) => {
        if (e.type == 'Date Picker') {
            const { set, question, date } = e;
            updateState(null, set, question, date);
        } else if (e.type == 'Checkbox List') {
            const { name } = e.target;
            const { set, question } = document.getElementsByName(name)[0].dataset;
            const data = [...document.querySelectorAll(`input[name="${name}"]:checked`)].map(e => e.value);
            updateState(name, set, question, data.join(';'));
        } else {
            const { name, value } = e.target;
            const { set, question } = document.getElementsByName(name)[0].dataset;
            updateState(name, set, question, value);
        }
    }

    const updateState = (name, set, questionId, value) => {
        let questionSet = currentForm.questionSets[set];
        let question = questionSet.questions.filter(q => q.id == questionId)[0];

        setCurrentForm(form => {
            question.userResponse = value;
            return form;
        });
    }

    const validateState = (form) => {
        let errors = 0;
        for (let qs = 0; qs < form.questionSets.length; qs++) {
            let canSkip = form.questionSets[qs].canSkip;
            for (let q = 0; q < form.questionSets[qs].questions.length; q++) {
                form.questionSets[qs].questions[q].validationMessages = [];
                if ((!canSkip || form.questionSets[qs].questions[q].isMandatory) && (!form.questionSets[qs].questions[q].userResponse || form.questionSets[qs].questions[q].userResponse == '')) {
                    form.questionSets[qs].questions[q].validationMessages.push('This field / question requires a response.');
                    errors++;
                }

                switch (form.questionSets[qs].questions[q].type) {
                    case 'Long Text':
                        if (form.questionSets[qs].questions[q].userResponse && form.questionSets[qs].questions[q].userResponse.length > 500) {
                            form.questionSets[qs].questions[q].validationMessages.push('Text is too long. The maximum number of characters is 500. You have used ' + form.questionSets[qs].questions[q].userResponse.length);
                            errors++;
                        }
                        break;
                    case 'Date Picker':
                        let dt = new Date(form.questionSets[qs].questions[q].userResponse);
                        if (dt > new Date()) {
                            form.questionSets[qs].questions[q].validationMessages.push('The date must not be in the future.');
                            errors++;
                        }
                        break;
                    case 'Dropdown List':
                        break;
                    case 'Radiobutton List':
                        break;
                    case 'Single Toggle':
                        break;
                }
            }
        }
        return errors;
    }

    const handleSubmitClick = async (e) => {
        submitButtonRef.current.setAttribute('disabled', true);
        e.preventDefault();
        const errs = validateState(currentForm);
        if (errs == 0) {
            await fetch('api/Submissions', {
                headers: {
                          'Accept' : 'application/json',
                    'Content-Type' : 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(currentForm)
            }).then(handleRedirect);
        } else {
            scroll(formTopRef);
        }
    }

    const scroll = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }

    const handleRedirect = (e) => {
        document.body.classList.remove('green-theme');
        document.body.classList.remove('red-theme');
        document.body.classList.remove('child-theme');
        if (e.status === 200) {
            setIsSubmitted(true);
            if (currentFormCategory === 'FFT') {
                props.history.push('/thankyou');
            } else {
                props.history.push('/received');
            }
        } else {
            props.history.push('/oops');
        }
    }

    const handleLink = (e) => {
        props.history.push(e.target.dataset.r);
    }
    
    const renderForms = (form) => {
        return (
            (form && form.id > 0) ?
                <div>
                    <Breadcrumb style={{ backgroundColor: '#768692'}}>
                        <BreadcrumbItem><a data-r="/" onClick={handleLink}>Home</a></BreadcrumbItem>
                        <BreadcrumbItem><a data-r={'/category/' + props.match.params.category} onClick={handleLink}>{props.match.params.category}</a></BreadcrumbItem>
                        <BreadcrumbItem active>{form.title}</BreadcrumbItem>
                    </Breadcrumb>
                    {currentFormCategory === 'FFT' ?                     
                    <div className="my-3 mx-auto text-center text-xl" style={{ width: '75%' }}>
                        <Stepper count={3} selectedStep={3}></Stepper>
                    </div> : <></> }
                    <h2 className="mb-4 display-4 text-primary text-center">
                        {form.category}
                    </h2>
                    <form name={form.id} key={'form' + form.id}>
                        <Card className='shadow border-primary my-4 mx-auto' style={{ maxWidth: '1180px' }}>
                            <CardHeader className="bg-primary">
                                <h3 className="my-0 text-white">{form.title}</h3>
                            </CardHeader>
                            {form.questionSets.map((set, idx) =>
                                <QuestionSet item={set} countOfSets={form.questionSets.length} key={'s' + idx} currentSetIndex={idx} changeEventHandler={handleOnChange} />
                            )}
                            <CardBody className="p-0">
                                <ListGroup flush>
                                    <ListGroupItem className="bg-light py-0">
                                        <Row>
                                            <Col xs="12" lg="6">
                                            </Col>
                                            <Col xs="12" lg="6" className="py-5 bg-response text-center" style={{
                                                backgroundColor: '#e8eef4'
                                            }}>
                                                <button type="submit" ref={submitButtonRef} onClick={(e) => handleSubmitClick(e)} className="btn btn-lg text-theme3 btn-primary mx-auto d-inline-block w-75">
                                                    Submit
                                                </button>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </form>
                </div> :
                <h3 className="text-danger mt-2">
                    The form is currently unavailable. Please try again later.
              </h3>
        );
    }

    const loadingHtml = <h3 className="text-center"><WaveTopBottomLoading />Please wait. Loading your {currentFormCategory} form.</h3>;

    return (
            <div ref={formTopRef}>
            {isLoading ? loadingHtml : renderForms(currentForm) }
            </div>
        );
}
