import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { WaveTopBottomLoading } from 'react-loadingg';
import { Row, Col } from 'reactstrap';

export const Home = () => {

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        document.body.classList.remove('green-theme');
        document.body.classList.remove('child-theme');
        document.body.classList.remove('red-theme');
        populateForms();
    }, []);

    const populateForms = async (e) => {
        await fetch('api/forms')
            .then(response => response.json())
            .then(data => {
                setCategories(data);
                setLoading(false);
            });
    }

    const loadingHtml = <h3 className="text-center py-3"><WaveTopBottomLoading />Please wait. Loading categories...</h3>;
    const loadedHtml = <Row>
        <h2 className="col-12 py-3 font-weight-bold mb-2 text-theme2">
            Select a survey to complete
        </h2>
        <Col xs="12">
            {categories.map(category =>
                <Link
                    key={category.id}
                    className="card p-3 d-inline-block mr-4 shadow align-top bg-theme3 text-theme3"
                    style={{ width: '200px', height: '200px', fontSize: '1.5rem', color: category.hexColourForeground, backgroundColor: category.hexColourBackground }}
                    to={{ pathname: '/Category/' + category.code }}>
                    {category.name}
                </Link>
            )}
        </Col>
    </Row>;
    return (
            loading ? loadingHtml : loadedHtml
           );
}
