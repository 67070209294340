import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ListGroupItem, ListGroup, Card } from 'reactstrap';
import { WaveTopBottomLoading } from 'react-loadingg';
import { FftCategoryDisplay } from './FftCategoryDisplay';


export const Categories = (props) => {

    const [forms, setForms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState(<h3 className="text-center"><WaveTopBottomLoading />Please wait. Loading forms...</h3>);

    useEffect(() => {
        async function getData() {
            const response = await fetch(`api/forms/${props.match.params.category}`);
            const data = await response.json();
            setForms(data);
            setLoading(false);
        }
        document.body.classList.remove('green-theme');
        document.body.classList.remove('child-theme');
        document.body.classList.remove('red-theme');
        getData(); 
    }, []);

    useEffect(() => {
        if (!loading) 
            setContent((props.match.params.category == 'FFT' ? <FftCategoryDisplay props={props} /> : renderForms(forms))) 
    }, [loading]);

    const renderForms = (frms) => {
        return (
            <>
                <h2 className="font-weight-bold mb-3 text-theme2">
                    Select a survey to complete
                </h2>
                <p>
                    Please read the descriptions and select the correct survey.
                </p>
                <Card className="shadow border-secondary survey-menu">
                    <ListGroup flush>
                        {frms.length > 0 ?
                            frms.map(form =>
                                <Link
                                    key={form.id}
                                    className="list-group-item bg-theme3 text-theme3 list-group-item-action"
                                    to={{ pathname: `/Forms/${props.match.params.category}/${form.id}` }}>
                                    {form.selectionText}
                                </Link>
                            ) :
                            <ListGroupItem>There are no forms or surveys to display here yet.</ListGroupItem>}
                    </ListGroup>
                </Card>
            </>
        );
    }

    return (
        <div>
            {content}
        </div>
    )
}

