import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import QuestionSet from '../other-controls/QuestionSet';
import { WaveTopBottomLoading  } from 'react-loadingg';
import './Forms.css';

class ValidationError {

    constructor(set, question, error) {
        this.questionSetId = set;
        this.questionId = question;
        this.error = error;
    }

}

export class SurveySelect extends Component {
    static displayName = SurveySelect.name;

    constructor(props) {
        super(props);
        this.state = {
            form: null,
            loading: true,
            isSubmitted: false,
            isSuccess: false,
            stylePath: './Forms.css'
        };
        this.formTopRef = React.createRef();
    }

    componentDidMount() {
        this.populateForms();
    }

    async populateForms() {
        const id = this.props.match.params.id;
        const category = this.props.match.params.category;
        let data = null;
        const response = await fetch(`api/Forms/${category}/0`)
            .then((e) => {
                switch (e.status) {
                    case 200:
                        this.state.isSuccess = true;
                        data = e.json().then((e) => {
                            if (this.state.isSuccess) {
                                this.setState({
                                    form: e,
                                    loading: false,
                                    isSubmitted: false,
                                    stylePath: this.state.stylePath
                                },
                                    () => {
                                        if (this.state.form) {
                                            
                                        }
                                    });
                            } else {
                                this.setState({
                                    loading: false
                                });
                            }
                        });
                        break;
                    default:
                        return;
                }
            });
    }

    handleOnChange = (e) => {
        const { name, value } = e.target;
        const { set, question } = document.getElementsByName(name)[0].dataset;
        this.updateState(name, set, question, value);
    }

    updateState(name, set, questionId, value) {
        let questionSet = this.state.form.questionSets[set];
        let question = questionSet.questions.filter(q => q.id == questionId)[0];
        this.setState(state => {
            question.userResponse = value;
            return state
        });
    }
        
    validateState(form) {
        let errors = 0;
        for (let qs = 0; qs < form.questionSets.length; qs++) {
            let canSkip = form.questionSets[qs].canSkip;
            for (let q = 0; q < form.questionSets[qs].questions.length; q++) {
                form.questionSets[qs].questions[q].validationMessages = [];
                if (!canSkip && (!form.questionSets[qs].questions[q].userResponse || form.questionSets[qs].questions[q].userResponse == '')) {
                    form.questionSets[qs].questions[q].validationMessages.push('This field / question requires a response.');
                    errors++;
                }

                switch (form.questionSets[qs].questions[q].type) {
                    case 'Long Text':
                        if (form.questionSets[qs].questions[q].userResponse && form.questionSets[qs].questions[q].userResponse.length > 500) {
                            form.questionSets[qs].questions[q].validationMessages.push('Text is too long. The maximum number of characters is 500. You have used ' + form.questionSets[qs].questions[q].userResponse.length);
                            errors++;
                        }
                        break;
                    case 'Date Picker':
                        let dt = new Date(form.questionSets[qs].questions[q].userResponse);
                        if (dt > new Date()) {
                            form.questionSets[qs].questions[q].validationMessages.push('The date must not be in the future.');
                            errors++;
                        }
                        else if (dt < new Date().setDate(dt.getDate() - 90)) {
                            form.questionSets[qs].questions[q].validationMessages.push('The date must not be more than 90 days in the past.');
                            errors++;
                        }
                        break;
                    case 'Dropdown List':
                        break;
                    case 'Radiobutton List':
                        break;
                    case 'Single Toggle':
                        break;
                }
            }
        }
        return errors;
    }

    handleSubmitClick = async(e) => {
        e.preventDefault();
        const form = this.state.form;
        const errs = this.validateState(form);
        if (errs == 0) {
            await fetch('api/Submissions', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(form)
            }).then(this.handleRedirect);
        } else {
            this.scroll(this.formTopRef);
            this.forceUpdate();
        }
    }

    scroll(ref) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }

    handleRedirect = (e) => {
        document.body.classList.remove('green-theme');
        document.body.classList.remove('red-theme');
        document.body.classList.remove('child-theme');
        if (e.status === 200) {
            this.props.history.push('/thankyou');
        } else {
            this.props.history.push('/oops');
        }
    }

    handleLink = (e) => {
        this.props.history.push(e.target.dataset.r);
    }


  static renderForms(form, component) {
      return (
          form && form.id > 0 ?
              <div>
                <Breadcrumb>
                    <BreadcrumbItem><a data-r="/" onClick={component.handleLink}>Home</a></BreadcrumbItem>
                    <BreadcrumbItem><a data-r={'/category/' + component.props.match.params.category} onClick={component.handleLink}>{component.props.match.params.category}</a></BreadcrumbItem>
                    <BreadcrumbItem active>{form.title}</BreadcrumbItem>
                </Breadcrumb>
                <h2 className="mb-4 display-4 text-primary text-center">
                    {form.category}
                </h2>
                <form name={form.id} onSubmit={(e) => component.handleFormSubmit(e)} key={'form' + form.id}>
                    <Card className='shadow border-primary my-4 mx-auto' style={{ maxWidth: '1180px' }}>
                        <CardHeader className="bg-primary">
                            <h3 className="my-0 text-white">{form.title}</h3>
                        </CardHeader>
                        {form.questionSets.map((set, idx) =>
                                <QuestionSet item={set} countOfSets={form.questionSets.length} key={'s' + idx} currentSetIndex={idx} changeEventHandler={component.handleOnChange} />
                        )}
                        <CardBody className="p-0">
                            <ListGroup flush>
                                <ListGroupItem className="bg-light py-0">
                                    <Row>
                                        <Col xs="12" lg="6">
                                        </Col>
                                        <Col xs="12" lg="6" className="py-5 bg-response text-center" style={{
                                            backgroundColor: '#e8eef4'
                                        }}>
                                                <button type="submit" onClick={(e) => component.handleSubmitClick(e)} className="btn btn-lg text-theme3 btn-primary mx-auto d-inline-block w-75">
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        </CardBody>
                    </Card>
                </form>
                <p className="my-3 text-xs text-center">
                    &copy; 2023 The Rotherham NHS Foundation Trust
                </p>
              </div> : 
              <h3 className="text-danger mt-2">
                  The form is currently unavailable. Please try again later.
              </h3>
    );
  }

    render() {

    const category = this.props.match.params.category;
    let contents = this.state.loading
        ? <h3 className="text-center"><WaveTopBottomLoading />Please wait. Loading your {category} form.</h3>
        : SurveySelect.renderForms(this.state.form, this)

    return (
        <div ref={this.formTopRef}> 
            {contents}
        </div>
    );
  }
}
