import React from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/pages/Home';
import { Forms } from './components/pages/Forms';
import { SurveySelect } from './components/pages/SurveySelect';
import { Oops } from './components/pages/Oops';
import { Login } from './components/pages/Login';
import { Logout } from './components/pages/Logout';
import { Register } from './components/pages/Register';
import { Thankyou } from './components/pages/Thankyou';
import { Received } from './components/pages/Received';
import { Categories } from './components/pages/Categories';
import { GlobalStateProvider } from './utils/globalState';
import './custom.css'
import Editor from './editor/editor';

export const App = () => (
    <GlobalStateProvider>
      <Layout>
            {/* <Route exact path='/' component={Home} /> */}
            <Route exact path='/' component={Home} />
            <Route path='/forms/:category/:id' component={Forms} />
            <Route path='/forms/:category/select' component={SurveySelect} />
            <Route path='/thankyou' component={Thankyou} />
            <Route path='/received' component={Received} />
            <Route path='/oops' component={Oops} />
            <Route path='/account/login' component={Login} />
            <Route path='/account/logout' component={Logout} />
            <Route path='/account/register' component={Register} />
            <Route path='/category/:category' component={Categories} />
        </Layout>
    </GlobalStateProvider>
);