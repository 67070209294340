import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SurveyLink } from './SurveyLink';

export function SurveyCard(props) {
    const hasLinks = props.links.filter(f => f.type == props.filter).length > 0;
    return (
        <>
        { hasLinks &&
            <div className="card survey-menu shadow text-white mb-3 bg-primary">
                <div className="card-body border-bottom">
                    <h4 className="card-title">{props.title}</h4>
                </div>
                <div className="list-group list-group-flush font-weight-bold">
                    {props.links.filter(f => f.type == props.filter).map((l, idx) =>
                        <SurveyLink url={l.url} title={l.title} key={idx + l.title} />
                    )}
                </div>
            </div>
        }
        </>
    );

}