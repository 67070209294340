import React, { useState, useRef } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import VisionSelector from './other-controls/VisionSelector';
import { useGlobalState } from '../utils/globalState';
import './NavMenu.css';

export function NavMenu() {

    const [collapsed, setCollapsed] = useState(true);
    const [state, dispatch] = useGlobalState();
    const [lowVisionMode, setLowVisionMode] = useState(false);

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow bg-secondary mb-2" dark>
                <Container fluid>
                    <NavbarBrand tag={Link} to="/">Forms</NavbarBrand>
                    <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                {!state.isLoggedIn ?
                                    <NavLink tag={Link} className="text-light" to="/account/login">Login</NavLink> :
                                    <NavLink tag={Link} className="text-light" to="/account/logout">Logout ({state.loggedInUser})</NavLink>}
                            </NavItem>
                        </ul>
                        <VisionSelector lowVisionMode={lowVisionMode} />
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
}
