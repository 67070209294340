import React from 'react';

class YesNoRadioButtons extends React.Component {

    render() {
        const ulClassName = "form-group form-group-radio" + (this.props.bindTo.displayColumns && this.props.bindTo.displayColumns > 0 ? " radio-columns" : "");

        return (
            <div className="radio-container">
                <ul className={ulClassName}>
                    <li className="radio-inline pr-4 d-inline-block bg-theme3" key={'q' + this.props.bindTo.id + '-Yes'}>
                        <input type="radio" name={'q' + this.props.bindTo.id}
                            data-set={this.props.bindTo.currentSetIndex}
                            data-question={this.props.bindTo.id}
                            className="radio-btn f0rm-check-input"
                            value="Yes"
                            onBlur={this.props.onChange}
                            onChange={this.props.onChange} />
                        <label className="f0rm-check-label label text-theme3">Yes</label>
                        <div className="bullet">
                            <div className="line zero"></div>
                            <div className="line one"></div>
                            <div className="line two"></div>
                            <div className="line three"></div>
                            <div className="line four"></div>
                            <div className="line five"></div>
                            <div className="line six"></div>
                            <div className="line seven"></div>
                        </div>
                    </li>
                    <li className="radio-inline ml-3 pr-4 d-inline-block bg-theme3" key={'q' + this.props.bindTo.id + '-No'}>
                        <input type="radio" name={'q' + this.props.bindTo.id}
                            data-set={this.props.bindTo.currentSetIndex}
                            data-question={this.props.bindTo.id}
                            className="radio-btn f0rm-check-input"
                            value="No"
                            onBlur={this.props.onChange}
                            onChange={this.props.onChange} />
                        <label className="f0rm-check-label label text-theme3">No</label>
                        <div className="bullet">
                            <div className="line zero"></div>
                            <div className="line one"></div>
                            <div className="line two"></div>
                            <div className="line three"></div>
                            <div className="line four"></div>
                            <div className="line five"></div>
                            <div className="line six"></div>
                            <div className="line seven"></div>
                        </div>
                    </li>
                </ul>
            </div>);
    }
}

export default YesNoRadioButtons;