import React, { useState, useRef, useFocus } from 'react';
import md5 from 'md5';
import { Row, Col, Card, CardBody, FormGroup, Form, Input, Label } from 'reactstrap';

export function Register(props) {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const inputRef = useRef(null);

    const handleSubmit = async(evt) => {
        evt.preventDefault();

        // 1.  Validate email address
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            setError('Email address is not valid.');
            return;
        }
        // 2.  Check password complexity
        if (password.length < 8) {
            setError('Password must be 8 or more characters.');
            return;
        }
        // 3.  Check password matches
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }
        // 4.  Register user details as Data Entry and Inactive
        await fetch(`api/Accounts/Register?name=${fullName}&email=${email}&hash=${md5(password)}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST'
        }).then(handleRedirect);
    }

    const handleRedirect = (e) => {
        if (e.status === 200) {
            props.history.push('/thankyou');
        } else {
            setError('The registration was not successful.');
        }
    }

    const resetClick = (evt) => {
        setFullName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        inputRef.current.focus();
    }    

    return (
            <Row>                
                <Col xs="12">
                    <h2 className="col-12 font-weight-bold mb-3 text-theme2">
                    Register for an account
                    </h2>
                <p className="ml-4" style={{ maxWidth: '650px' }}>
                    Registering for a Data Entry account allows a staff member to enter the completed paper forms in to
                    the system. The account is not automatically available and must be authorised by an Administrator.
                </p>
                {error && 
                    <p className="text-danger">Error - {error}</p>
                }
                    <Card style={{ maxWidth: '650px' }} className="border-secondary shadow my-2 ml-4">
                        <CardBody>
                            <h4 className="mb-4">Please enter your details below.</h4>
                            <hr />
                            <Form className="form-login" onSubmit={handleSubmit}>
                                <FormGroup row>
                                    <Label sm="4">Full Name</Label>
                                    <Col sm="8">
                                        <Input id="fullName" ref={inputRef} type="text" value={fullName} onChange={(ev) => setFullName(ev.target.value)} placeholder="Your Full Name" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4">Email address</Label>
                                    <Col sm="8">
                                        <Input id="emailAddress"type="text" value={email} onChange={(ev) => setEmail(ev.target.value)} placeholder="Your NHS.Net Email Address" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4">Password</Label>
                                    <Col sm="8">
                                        <Input id="password" type="password" value={password} onChange={(ev) => setPassword(ev.target.value)} placeholder="Your Password" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4">Confirm Password</Label>
                                    <Col sm="8">
                                        <Input id="confirmPassword" type="password" value={confirmPassword} onChange={(ev) => setConfirmPassword(ev.target.value)} placeholder="Confirm Your Password" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4"></Label>
                                    <Col sm="8">
                                        <button type="submit" className="btn btn-primary px-4">Register</button>
                                        <button type="reset" onClick={resetClick} className="btn btn-light btn-outline-secondary px-4 float-right">Reset</button>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
    );
}
