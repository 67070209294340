import React, { useState, useRef, useEffect } from 'react';
import md5 from 'md5';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../utils/globalState';
import { Row, Col, Card, CardBody, CardFooter, FormGroup, Form, Input, Label } from 'reactstrap';

export function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [state, dispatch] = useGlobalState();
    const inputRef = useRef(null);

    useEffect(() => {
        document.body.classList.remove('green-theme');
        document.body.classList.remove('child-theme');
        document.body.classList.remove('red-theme');
    }, []);

    const resetClick = (evt) => {
        setEmail("");
        setPassword("");
        inputRef.current.focus();
    }    
    
    const handleSubmitClick = async (e) => {
        e.preventDefault();
        await fetch(`api/Accounts/Signin?email=${email}&hash=${md5(password)}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(response => response.json())
            .then(handleRedirect)
            .catch(error => {
                console.log(error);
            });
    }

    const handleRedirect = (e) => {
        if (e.id > 0) {
            dispatch({
                isLoggedIn: true,
                loggedInUser: e.emailAddress,
                loggedInDateTime: new Date(),
                entryMethod: 'Paper'
            });
            props.history.goBack();
        } else {
            console.log(e);
            props.history.push('/oops');
        }
    }

    return (
            <Row>
                <h2 className="col-12 font-weight-bold mb-3 text-theme2">
                    Staff Data Entry Login
                </h2>
                <Col xs="12">
                    <Card style={{ maxWidth: '650px' }} className="border-secondary shadow my-2 ml-4">
                        <CardBody>
                            <h4 className="mb-4">Please enter your details below.</h4>
                            <hr />
                            <Form className="form-login" onSubmit={handleSubmitClick}>
                                <FormGroup row>
                                    <Label sm="4">Email address</Label>
                                    <Col sm="8">
                                        <Input id="emailAddress" ref={inputRef} type="text" value={email} onChange={(ev) => setEmail(ev.target.value)} placeholder="Your NHS.Net Email Address" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4">Password</Label>
                                    <Col sm="8">
                                        <Input id="password" type="password" value={password} onChange={(ev) => setPassword(ev.target.value)} placeholder="Your FFT Password" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4"></Label>
                                    <Col sm="8">
                                        <button type="submit" className="btn btn-primary px-4">Login</button>
                                        <button type="reset" onClick={resetClick} className="btn btn-light btn-outline-secondary px-4 float-right">Reset</button>
                                    </Col>
                                </FormGroup>
                            </Form>
                    </CardBody>
                    <CardFooter>
                            Don't have an account? &nbsp;
                            <Link
                                to={{ pathname: `/account/register` }}>
                                Register here
                            </Link>.
                    </CardFooter>
                </Card>
                </Col>
            </Row>
    );
}
