import React from 'react';
import './CheckBoxList.css';

class CheckBoxList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 'Checkbox List',
            set: this.props.bindTo.currentSetIndex,
            target: { name: 'q' + this.props.bindTo.id },
            question: this.props.bindTo.id
        };
    }

    render() {
        const ulClassName = "form-group form-group-check" + (this.props.bindTo.displayColumns && this.props.bindTo.displayColumns > 0  ? " check-columns" : "");
        return (
            <div className="check-container">
                <ul className={ulClassName}>
                {this.props.bindTo.responses.map(response =>
                    <li className="check-inline check-columns bg-theme3" key={this.props.bindTo.id + '-' + response.value}>
                        <input type="checkbox" name={'q' + this.props.bindTo.id}
                            data-set={this.props.bindTo.currentSetIndex}
                            data-question={this.props.bindTo.id}
                            className="check-btn f0rm-check-input"
                            value={response.value}
                            onBlur={e => {
                                this.setState({ values: e[0] }, () => this.props.onChange(this.state));
                            }}
                            onChange={e => {
                                this.setState({ values: e[0] }, () => this.props.onChange(this.state));
                            }} />
                        <label className="f0rm-check-label label text-theme3">{response.text}</label>
                        <div className="bullet">
                            <div className="line zero"></div>
                            <div className="line one"></div>
                            <div className="line two"></div>
                            <div className="line three"></div>
                            <div className="line four"></div>
                            <div className="line five"></div>
                            <div className="line six"></div>
                            <div className="line seven"></div>
                        </div>
                    </li>
                )}
                </ul>
            </div>);
    }
}

export default CheckBoxList;