import React from 'react';
import { CardBody, ListGroup, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Question from './Question';
import './QuestionSet.css';

class QuestionSet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            canSkip: false,
            questions: [],
            title: '',
            subtitle: '',
            totalSets: 0,
            currentSetIndex: 0,
            isExpanded: true,
            isHidden: false,
            iconClass: 'text-secondary rotate'
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.questions && nextProps.questions !== prevState.questions) {
            return { questions: nextProps.questions };
        }
        else return null;
    }

    componentDidMount() {
        this.setState({
            id: this.props.item.id,
            title: this.props.item.title,
            subtitle: this.props.item.subtitle,
            questions: this.props.item.questions,
            canSkip: this.props.item.canSkip,
            hasMandatory: this.props.item.questions.filter(q => q.isMandatory).length,
            totalSets: this.props.countOfSets,
            currentSetIndex: this.props.currentSetIndex
        });        
    }

    toggleQuestionSet = () => {
        this.setState({
            isExpanded: !this.state.isExpanded,
            iconClass: !this.state.isExpanded ? 'text-secondary rotate' : 'text-secondary rotate down'
        });
    }
    
    render() {
        return (
            <React.Fragment>
            <a className="border-top border-primary question-set-header" onClick={this.toggleQuestionSet} role="button" aria-controls={'set' + this.state.currentSetIndex} data-target={'#set' + this.state.currentSetIndex} key={'qs' + this.state.id}>
                <h5 className="px-3 py-2 my-0 text-theme2">
                    <FontAwesomeIcon icon={faChevronDown} className={this.state.iconClass} />&nbsp; {this.state.title}
                    <span className="text-uppercase small float-right text-primary font-weight-bold">
                        Section {this.state.currentSetIndex + 1} of {this.state.totalSets}
                    </span>
                    <p className="my-0">{this.state.subtitle}</p>
                </h5>
                {!this.state.canSkip ?
                    <p className="mt-0 mb-1 pl-3 small font-weight-bold text-primary">
                        * All questions in this section are mandatory
                    </p> : (this.state.hasMandatory ? 
                        <p className="mt-0 mb-1 pl-3 small font-weight-bold text-primary">
                        * There are mandatory questions in this section
                        </p> : '')}
            </a>
            <Collapse className="p-0 question-set-body" id={'set' + this.state.currentSetIndex} isOpen={this.state.isExpanded}>
                <ListGroup flush>
                    {this.state.questions && this.state.questions.map((q, questionIndex) =>
                        <Question item={q} key={'q' + questionIndex} currentSetIndex={this.state.currentSetIndex} currentQuestionIndex={questionIndex} changeEventHandler={this.props.changeEventHandler} />
                    )}
                </ListGroup>
            </Collapse>
        </React.Fragment>);
    }
}

export default QuestionSet;