import React, { useEffect } from 'react';
import { useGlobalState } from '../../utils/globalState';

export function Logout(props) {
    const [state, dispatch] = useGlobalState();

    useEffect(() => {
        dispatch({
            isLoggedIn: false,
            loggedInUser: "",
            loggedInDateTime: null,
            entryMethod: 'Online'
        });
        props.history.push('/');
    }, []);

    return (
        <></>
    );
}
