import React from 'react';
import './DropdownList.css';

export function DropdownList(props) {

    const groups = [...new Set(props.bindTo.responses.map(g => g.grouping))];

    return (
        <div className="select-container">
            <select
                className="form-control text-theme3 bg-theme3 select-css"
                name={'q' + props.bindTo.id}
                data-set={props.bindTo.currentSetIndex}
                data-question={props.bindTo.id}
                onBlur={props.onChange}
                onChange={props.onChange}>
                <option value="">Please select an option...</option>
                {groups.map(grp =>
                    <optgroup label={grp}>
                    {props.bindTo.responses.filter(r => r.grouping === grp).map(response =>
                        <option key={response.value} value={response.value}>{response.text}</option>
                    )}
                    </optgroup>
                )}
            </select>
        </div>);
}