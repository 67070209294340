import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';

export class Layout extends Component {

  render () {
    return (
      <div className="main-container">
        <NavMenu />
        <Container fluid>
          {this.props.children}
        </Container>
        <Footer />
      </div>
    );
  }
}
