import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function PatientSearch(props) {

    const [searchTerm, setSearchTerm] = useState();

    const onKeyDown = (e) => {
        const [value] = e.target;
        setSearchTerm(value);
    }

    useEffect(() => {
        const uri = 'https://api.rhr.care/';
    },[searchTerm])

    return (
        <div className="input-group mb-3">
        <input type="text"
                className="form-control col-lg-6 bg-theme3 text-theme3"
                data-set={props.bindTo.currentSetIndex}
                data-question={props.bindTo.id}
                name={'q' + props.bindTo.id}
                id={'q' + props.bindTo.id}
                onChange={(e) => onKeyDown(e)} />
            <div className="input-group-append">
                <button className="btn btn-primary" id={`searchButtonQ` + props.bindTo.id}><FontAwesomeIcon icon={faSearch} /></button>
            </div>
        </div>
    );
}