import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLowVision, faEye } from '@fortawesome/free-solid-svg-icons';
import './VisionSelector.css';

class VisionSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lowVisionMode: false
        };
    }

    updateVisionModeButtonClick = (e) => {
        !this.state.lowVisionMode ? document.body.classList.add('high-contrast') : document.body.classList.remove('high-contrast');
        this.setState({ lowVisionMode: !this.state.lowVisionMode });
        this.forceUpdate();
    }
    
    render() {
        let vision = !this.state.lowVisionMode ?
            <button type="button" className="mr-2 btn btn-dark text-warning font-weight-bold" onClick={this.updateVisionModeButtonClick}>
                <FontAwesomeIcon icon={faLowVision} /> Poor Vision
              </button> :
            <button type="button" className="mr-2 btn btn-primary" onClick={this.updateVisionModeButtonClick}>
                <FontAwesomeIcon icon={faEye} /> Standard
              </button>
        return (<div>{ vision }</div>);
    }
}

export default VisionSelector;