import React, { Component } from 'react';

export class Oops extends Component {
    static displayName = Oops.name;

    render() {
        return (
            <div className="text-center">
                <h2 className="text-theme3 my-5 display-3">
                    Oops! That didn't work. Please try again later.
                </h2>
                <p>
                    If after 48 hours you are still unable to complete a survey, please request a paper form from the Ward.
                </p>
            </div>
        );
    }
}
