import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { Stepper } from '../other-controls/Stepper';

export function SurveyCardFilter(props) {

    const [filterSelected, setFilterSelected] = useState(null);
    const filters = ['Adult', 'Child', 'Parent/Carer'];
    const handleClick = (filter) => {
        setFilterSelected(filter);
        props.handler(filter);
    }
    const selectAnotherClick = (e) => {
        setFilterSelected(null);
        props.handler(null);
    }

    return (
        <div className="my-3 mx-auto text-center text-xl" style={{ width: '75%' }}>
            {filterSelected &&
                <>
                    <Stepper count={3} selectedStep={2} />
                    <h2 className="mb-4 display-4 text-primary text-center">Friends &amp; Family Test</h2>
                    <p className="h5 text-theme3">Showing <strong>{filterSelected}</strong> forms.</p>
                    <button type="button" onClick={selectAnotherClick} className="btn btn-lg btn-link text-primary font-weight-bold">Choose again</button>
                </>
            }
            {!filterSelected &&
                <>
                    <Stepper count={3} selectedStep={1} />
                    <h2 className="mb-4 display-4 text-primary text-center">Friends &amp; Family Test</h2>
                    <p className="h5 text-theme3">I'm a...</p>
                    <ButtonGroup>
                        {filters.map((filter, idx) =>
                            <Button size="lg" color="primary" key={idx + filter} onClick={() => handleClick(filter)}>{filter}</Button>
                        )}
                    </ButtonGroup>
                </>
            }
        </div>
    );

}