import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../utils/globalState';

export function Thankyou() {
    const displayName = Thankyou.name;
    const [state, dispatch] = useGlobalState();
    const doAnother =
        <p>
            Complete another {state.selectedCategory} form?  <Link
                to={{ pathname: `/category/${state.selectedCategory}` }}>
                        Click here
            </Link>.
        </p>;
    return (
        <div className="text-center">
            <h2 className="text-theme3 my-5 display-3">
                Thank you for the feedback!
            </h2>
            {state.isLoggedIn ? doAnother : '' }
        </div>
    );
}
