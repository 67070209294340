import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../utils/globalState';

export function Received() {
    const displayName = Received.name;
    const [state, dispatch] = useGlobalState();

    return (
        <div className="text-center">
            <h2 className="text-theme3 my-5 display-3">
                All done! Your information has been received.
            </h2>
        </div>
    );
}
